<template>
  <v-container fluid class="pa-0">
    <v-toolbar color="primary" dark flat>
      <v-toolbar-title overflow-auto> Forecasts </v-toolbar-title>
      <v-spacer />
      <DialogForm title="New Forecast: IHR" icon="add" max-width="800" :submit="addForecast">
        <template #default> <IHRForm v-model="formData" /> </template>
      </DialogForm>
    </v-toolbar>
    <FeathersServiceTable
      service="forecasts"
      :headers="headers"
      :params="tableParams"
      ref="serviceTable"
      :auto-refresh="10"
    >
      <template #item.status="{ item }">
        <v-tooltip top>
          <template #activator="{ on }">
            <v-icon
              v-if="item.status !== 'IN_PROGRESS'"
              :color="statusIcon[item.status].color"
              v-on="on"
              >{{ statusIcon[item.status].icon }}</v-icon
            >
            <v-progress-circular
              v-else
              indeterminate
              color="secondary"
              size="23"
              v-on="on"
            ></v-progress-circular>
          </template>
          <span>{{ item.status | formatStatus }}</span>
        </v-tooltip>
      </template>

      <template #item.market_name="{ item }">
        {{ item.input.market_name.toUpperCase() }}
      </template>

      <template #item.forecast_horizon="{ item }">
        {{ forecastHorizon(item.input) }}
      </template>
      <template #item.energy_prices="{ item }">
        <DialogForecastOutput
          :item-id="item.id"
          output-type="energy_prices"
          title="Forecasted Energy Prices"
          @close="refreshTable()"
        />
      </template>
      <template #item.reserves="{ item }">
        <DialogForecastOutput
          :item-id="item.id"
          output-type="reserves"
          title="Forecasted Reserves"
          @close="refreshTable()"
        />
      </template>
      <template #item.actions="{ item }">
        <DialogForm
          title="Edit Forecast"
          icon="create"
          max-width="800"
          :submit="saveCloneForecast"
          :value="cloneData"
          @open="fillCloneData(item)"
        >
          <template #activator="{ on: dialogOn }">
            <v-tooltip top>
              <template #activator="{ on: tooltipOn }">
                <v-btn icon small v-on="{ ...dialogOn, ...tooltipOn }">
                  <v-icon>create</v-icon>
                </v-btn>
              </template>
              <span>Edit</span>
            </v-tooltip>
          </template>
          <template #default="form">
            <IHRForm v-model="form.item" />
          </template>
        </DialogForm>
        <v-tooltip top>
          <template #activator="{ on }">
            <v-btn icon small v-on="on" @click="copyForecast(item)">
              <v-icon>file_copy</v-icon>
            </v-btn>
          </template>
          <span>Copy</span>
        </v-tooltip>
        <v-tooltip top>
          <template #activator="{ on }">
            <v-btn icon small v-on="on" @click="deleteForecast(item.id)">
              <v-icon>delete</v-icon>
            </v-btn>
          </template>
          <span>Delete</span>
        </v-tooltip>
        <v-tooltip top>
          <template #activator="{ on }">
            <v-btn
              icon
              small
              :loading="downloadPending === item.id"
              :disabled="Boolean(downloadPending)"
              v-on="on"
              @click="downloadOutputs(item)"
            >
              <v-icon>get_app</v-icon>
            </v-btn>
          </template>
          <span>Download Outputs</span>
        </v-tooltip>
      </template>
    </FeathersServiceTable>
  </v-container>
</template>

<script>
import Papa from "papaparse";
import download from "downloadjs";
import { mapActions, mapMutations } from "vuex";
import filters from "@/mixins/filters";
import Chart from "@/components/charts/Plotly";
import { deepClone } from "@/plugins/utils";

export default {
  name: "Forecasts",
  components: {
    DialogForecastOutput: () => import("@/components/DialogForecastOutput"),
    DialogForm: () => import("@/components/DialogForm"),
    IHRForm: () => import("@/components/IHRForm"),
    FeathersServiceTable: () => import("@/components/FeathersServiceTable"),
  },
  mixins: [filters],
  props: {},
  data() {
    return {
      headers: [
        { text: "ID", value: "id" },
        { text: "Method", value: "type" },
        { text: "Market Name", value: "market_name" },
        { text: "Node Name", value: "input.node_name" },
        { text: "Forecast Horizon", value: "forecast_horizon" },
        { text: "Status", value: "status" },
        { text: "Energy Prices", value: "energy_prices" },
        { text: "Reserves", value: "reserves" },
        { text: "", value: "actions" },
        { text: "", value: "download" },
      ],
      comodities: ["Energy"],
      formData: {
        type: "IHR",
        input: {
          market_name: "pjm",
          forecast_limit: false,
          max_limit: 0,
          min_limit: 0,
        },
      },
      cloneData: {},
      chartLayout: {
        xaxis: {
          autorange: true,
          rangeslider: {},
          type: "date",
        },
      },
      tableParams: {
        query: {
          $sort: { id: -1 },
          $select: ["id", "type", "input", "status"],
        },
      },
      downloadPending: false,
      statusIcon: {
        ERROR: { color: "red", icon: "error" },
        IN_PROGRESS: {},
        DONE: { color: "green", icon: "check_circle" },
        NOT_STARTED: { color: "", icon: "not_started" },
      },
    };
  },
  computed: {
    plotlyChart() {
      return Chart;
    },
  },
  watch: {},
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  beforeUpdate() {},
  updated() {},
  activated() {},
  deactivated() {},
  beforeDestroy() {},
  destroyed() {},
  methods: {
    ...mapActions({
      createForecast: "forecasts/create",
      deleteForecast: "forecasts/confirmRemove",
      getOutput: "forecasts/get",
      findOutput: "forecasts/find",
      saveForecast: "forecasts/patch",
    }),
    ...mapMutations({
      removeForecast: "forecasts/removeItem",
      updateForecast: "forecasts/updateItem",
      addForecast: "forecasts/updateItem",
    }),
    addForecast() {
      return this.createForecast(this.formData);
    },
    downloadableOutputs(outputs) {
      const data = [];
      outputs.hourly.forEach((element) => {
        data.push({
          timestamp: element.timestamp,
          energy_price_low: element.energy_price.low,
          energy_price_base: element.energy_price.base,
          energy_price_high: element.energy_price.high,
          reserves_primary_low: element.reserves.primary.low,
          reserves_primary_base: element.reserves.primary.base,
          reserves_primary_high: element.reserves.primary.high,
          reserves_secondary_low: element.reserves.secondary.low,
          reserves_secondary_base: element.reserves.secondary.base,
          reserves_secondary_high: element.reserves.secondary.high,
          reserves_tertiary_low: element.reserves.tertiary.low,
          reserves_tertiary_base: element.reserves.tertiary.base,
          reserves_tertiary_high: element.reserves.tertiary.high,
        });
      });
      return data;
    },
    refreshTable() {
      this.$refs.serviceTable.findService();
    },
    downloadOutputs(item) {
      this.downloadPending = item.id;
      this.findOutput({
        query: { id: item.id, $select: ["id", "output"] },
      })
        .then((res) => {
          if (res.total && res.data[0].output) {
            const csv = Papa.unparse(this.downloadableOutputs(res.data[0].output));
            download(
              csv,
              `${item.input.market_name}_${item.input.fuel_type}_${item.input.start_of_forecast}_${item.input.end_of_forecast}_output.csv`,
              "text/csv"
            );
          }
          this.downloadPending = false;
          this.removeForecast(item);
          this.refreshTable();
        })
        .catch(() => {
          this.downloadPending = false;
        });
    },
    forecastHorizon(input) {
      return `${input.start_of_forecast} - ${input.end_of_forecast}`;
    },
    fillCloneData(item) {
      this.cloneData = deepClone(item);
    },
    saveCloneForecast() {
      return this.saveForecast([this.cloneData.id, this.cloneData, {}]);
    },
    copyForecast(item) {
      const { id, ...settings } = item;
      this.createForecast(settings);
    },
  },
};
</script>

<style></style>
