var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-toolbar',{attrs:{"color":"primary","dark":"","flat":""}},[_c('v-toolbar-title',{attrs:{"overflow-auto":""}},[_vm._v(" Forecasts ")]),_c('v-spacer'),_c('DialogForm',{attrs:{"title":"New Forecast: IHR","icon":"add","max-width":"800","submit":_vm.addForecast},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('IHRForm',{model:{value:(_vm.formData),callback:function ($$v) {_vm.formData=$$v},expression:"formData"}})]},proxy:true}])})],1),_c('FeathersServiceTable',{ref:"serviceTable",attrs:{"service":"forecasts","headers":_vm.headers,"params":_vm.tableParams,"auto-refresh":10},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(item.status !== 'IN_PROGRESS')?_c('v-icon',_vm._g({attrs:{"color":_vm.statusIcon[item.status].color}},on),[_vm._v(_vm._s(_vm.statusIcon[item.status].icon))]):_c('v-progress-circular',_vm._g({attrs:{"indeterminate":"","color":"secondary","size":"23"}},on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm._f("formatStatus")(item.status)))])])]}},{key:"item.market_name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.input.market_name.toUpperCase())+" ")]}},{key:"item.forecast_horizon",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.forecastHorizon(item.input))+" ")]}},{key:"item.energy_prices",fn:function(ref){
var item = ref.item;
return [_c('DialogForecastOutput',{attrs:{"item-id":item.id,"output-type":"energy_prices","title":"Forecasted Energy Prices"},on:{"close":function($event){return _vm.refreshTable()}}})]}},{key:"item.reserves",fn:function(ref){
var item = ref.item;
return [_c('DialogForecastOutput',{attrs:{"item-id":item.id,"output-type":"reserves","title":"Forecasted Reserves"},on:{"close":function($event){return _vm.refreshTable()}}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('DialogForm',{attrs:{"title":"Edit Forecast","icon":"create","max-width":"800","submit":_vm.saveCloneForecast,"value":_vm.cloneData},on:{"open":function($event){return _vm.fillCloneData(item)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var dialogOn = ref.on;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltipOn = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","small":""}},Object.assign({}, dialogOn, tooltipOn)),[_c('v-icon',[_vm._v("create")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit")])])]}},{key:"default",fn:function(form){return [_c('IHRForm',{model:{value:(form.item),callback:function ($$v) {_vm.$set(form, "item", $$v)},expression:"form.item"}})]}}],null,true)}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.copyForecast(item)}}},on),[_c('v-icon',[_vm._v("file_copy")])],1)]}}],null,true)},[_c('span',[_vm._v("Copy")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.deleteForecast(item.id)}}},on),[_c('v-icon',[_vm._v("delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","small":"","loading":_vm.downloadPending === item.id,"disabled":Boolean(_vm.downloadPending)},on:{"click":function($event){return _vm.downloadOutputs(item)}}},on),[_c('v-icon',[_vm._v("get_app")])],1)]}}],null,true)},[_c('span',[_vm._v("Download Outputs")])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }